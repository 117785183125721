<p>Cargue Data</p>

<div class="row">
  <div class="col-md-4 col-xs-12">
    <select id="collections" class="form-control" (change)="loadFields($event.target.value)">
      <option *ngFor="let order of listCollections; let i = index" [value]="listCollections[i]">
        {{listCollections[i]}}
      </option>
    </select>
    <p *ngIf="collectionSelected === 'FiltroPasos'" class="text-warning">Para la columna de restricción no dejar espacios después de la coma.</p>
  </div>
  <div class="col-md-3 col-xs-12">
    Collection have a relation with other?
    <input type="checkbox" [checked]="collectionRelational === true"
      (change)="$event.target.checked? (collectionRelational = true) : (collectionRelational = false)">

  </div>
  <div class="col-md-5 col-xs-12">
    <input type="file" (change)="addfile($event)" placeholder="Upload file"
      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
  </div>
</div>



<div *ngIf="viewFileMatch">
  <p>Primer registro</p>
  <p>{{itemlist | json }}</p>

  <table class="table table-striped" style="width: 100%;">
    <caption style="display: none">Table excel</caption>
    <thead>
      <tr>
        <th scope="col">Excel field</th>
        <th scope="col">Collection Field</th>
        <th scope="col">Select BD Luker field</th>
        <th scope="col">IsRelational?</th>
        <th scope="col">Collection</th>
        <th scope="col">Field</th>
        <th scope="col">Resumen</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of listFieldbyCollection; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <td>
          <p>{{field}} ::</p>
        </td>
        <td>
          <select name="listFields[n]" id="listFields[n]" class="form-control"
            (change)="matchField($event.target.value,field)">
            <option [ngValue]=""></option>
            <option *ngFor="let fieldCollection of listFieldbyCollection; let n = index" [ngValue]="listFieldbyCollection[n]">
              {{listFieldbyCollection[n]}}
            </option>
          </select></td>
        <td>
          <input type="checkbox" name="checkbox{{i}}" value="{{i}}" [checked]="options.indexOf(i) >= 0"
            (change)="updateCheckedOptions(field, $event)" />
          {{listFieldbyCollection[i]}}
        </td>
        <td>
          <div *ngIf="this.optionsMap[field]==true"> <select id="collection" class="form-control"
              (change)="loadFieldsForRelation($event.target.value,field)">
              <option [ngValue]="">Select collection</option>
              <option *ngFor="let collection of listCollections; let j = index" [(ngValue)]="listCollections[j]">
                {{collection}}
              </option>
            </select></div>
        </td>
        <td>
          <div *ngIf="this.optionsMap[field]==true">
            <select id="relational" class="form-control" (change)="keepRelation($event.target.value)">
              <option [ngValue]="">Select Relation</option>
              <option *ngFor="let relational of listfieldbyRelation[field] ; let f = index"
                [(ngValue)]="listfieldbyRelation[f]">
                {{relational}}
              </option>
            </select>
          </div>
        </td>

        <td>
          <button type="button" class="btn btn-default" (click)="addRelation(field,i)"
            class="btn btn-primary">Add</button>
        </td>
      </tr>
    </tbody>
  </table>
  <p>Relaciones agregadas</p>
  <p>{{relationalArray | json }}</p>

  <div class="alert alert-primary" role="alert">
    {{resultadoCargue}}
  </div><br>
  <button type="button" class="btn btn-default" (click)="Cargar()" class="btn btn-primary">Cargar</button>

</div>