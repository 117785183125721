import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import "rxjs/add/operator/map";
import { map } from "rxjs/operators";
import { PublicModule } from "../public.module";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { MainService } from "./main.service";
import { User } from "../interfaces/user.interface";

@Injectable({
  providedIn: "root",
})

export class uploadDataService {

  constructor(
    public http: Http,
    private mainService: MainService
  ) {
  }

  /**
   * Traer todas las collecciones existente sde la base de datos asociada
   * @returns promesa con las collecciones
   */
  getCollections(): Promise<{ users: User[] }> {
    return new Promise((resolve, reject) => {
      this.mainService.get({ api: "api/getCollections/" }).subscribe(
        (res) => {
          resolve({ users: res });
        }, (err) => {
          reject({ error: err, msg: "Ocurrió un error al cargar el listado de colecciones" });
        }
      );
    });
  }

  /**
  * Trae los campos correspondientes aun a colleccion seleccionada.
  * @returns promesa con los campos o atributos de la colleccion
  */
  getFields(nameCollection): Promise<{ users: User[] }> {
    return new Promise((resolve, reject) => {
      this.mainService.get({ api: "api/getField/" + nameCollection + "/" }).subscribe(
        (res) => {
          resolve({ users: res });
        }, (err) => {
          reject({ error: err, msg: "Ocurrió un error al cargar campos de la coleccion" });
        }
      );
    });
  }


  /**
  *Inserta la informacion en la coleccion seleccionada 
  * @returns respuesta del proceso segun el backend
  */
  addCollection(data): Promise<{ users: User[] }> {
    return new Promise((resolve, reject) => {
      this.mainService.post({ api: "api/addCollection/", data: data }).subscribe(
        (res) => {
          resolve({ users: res });
        }, (err) => {
          reject({ error: err, msg: "Ocurrió un error al cargar la informacion en la colleccion" });
        }
      );
    });
  }

}
